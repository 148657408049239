
<div class="modal" tabindex="-1" ref="master">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header" v-show="!!$slots.header">
                <slot name="header"></slot>
                <button @click="close"
                    class="btn-close" 
                    :aria-label="$_locale('close')">
                </button>
            </div>
            <div class="modal-body">
                <slot></slot>
            </div>
            <div class="modal-footer" v-show="!!$slots.footer">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</div>
