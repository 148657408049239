import {
    saveFilters,
    loadFilters,
    getType
} from './helpers.js'

export default {
    data: () => ({
        namespace: null,
        rows: [],
        search: '',
        currentSortDir: 'asc',
        currentSort: '',
    }),
    computed: {
        filteredRows() {
            const rows = this.rows;
            const searchArgs = this.search.trim().toLowerCase().split(',');
            const headers = Object.keys(this.headers);
            
            return rows.filter(row => {
                if (this.search.trim().length === 0) return true;
                
                for (let key of headers) {
                    let value = row[key]
                    // convert numbers to strings
                    if (isFinite(value)) {
                        value = ''+value
                    }

                    if (typeof value !== 'string') {
                        continue;
                    }

                    for (let search of searchArgs) {
                        if (search.trim().length === 0) {
                            continue;
                        }
                        if (value.toLowerCase().includes(search.trim())) {
                            return true;
                        }
                    }
                }

                return false;
            })
        },
        sortedRows() {
            const rows = this.filteredRows;
            const currentSortDir = this.currentSortDir;
            const currentSort = this.currentSort;

            return rows.sort((a, b) => {
                const modifier = currentSortDir === 'desc' ? -1 : 1;
                
                if(!a[currentSort] && !b[currentSort])
                    return 0;
                else if(!b[currentSort])
                    return 1 * modifier;
                else if(!a[currentSort])
                    return -1 * modifier;
                else if (a[currentSort] < b[currentSort])
                    return -1 * modifier;
                else if (a[currentSort] > b[currentSort])
                    return 1 * modifier;
                
                return 0;
            });
        },
    },
    watch: {
        search(val) {
            if (val && this.namespace) {
                saveFilters(this.namespace, val)    
            }            
        },
    },
    methods: {
        setSort(key, forceSort = null) {
            if (forceSort) {
                this.currentSortDir = forceSort;
            } else if (key === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = key;
        },
    },
    beforeMount() {
        if (!this.namespace) return;
        
        const search = loadFilters(this.namespace, null)

        if (getType(search) === String) {
            this.search = search
        }
    },
}