import debounce from '../../common/debounce.js'

export function getType(target) {
    const types = {
        'string': String,
        'number': Number,
        'object': Object,
        'function': Function,
    }

    if (typeof target === 'undefined') {
        return undefined;
    }

    if (target === null) {
        return null;
    }

    if (Array.isArray(target)) {
        return Array;
    }

    return types[typeof target];
}

export function isMoment(anyObj) {
    if (typeof window.moment === 'undefined') {
        return false;
    }

    return window.moment.isMoment(anyObj);
}

export function toDate(anyObj, forceTime = '00:00:00') {
    if (isMoment(anyObj)) {
        return anyObj.toDate();
    }

    const date = new Date(anyObj);

    if (date.toString() === 'Invalid Date') {
        return null;
    }

    if (getType(forceTime) === String) {
        const [ hours, minutes, seconds ] = forceTime.split(':')

        date.setHours(hours)
        date.setMinutes(minutes)
        date.setSeconds(seconds)
        date.setMilliseconds(0)
    }

    return date;
}

const saveLocalStorage = debounce((key, value) => {
    try {
        localStorage[key] = JSON.stringify(value)
    } catch(e) {
        console.warn('TablePagesMixins.saveFilters', e)
    }
}, 400);

export function saveFilters(name, filters) {
    const key = `VueDataTable.filters.${ name }`
    
    saveLocalStorage(key, filters)
}

export function loadFilters(name, filters) {
    const key = `VueDataTable.filters.${ name }`
    
    if (!localStorage[key]) return;

    try {
        const savedFilters = JSON.parse(localStorage[key])
        // SimpleSearch case, only single string
        if (typeof savedFilters === 'string') {
            return savedFilters
        }
        // Filters object is nullish or something for some reason, skip
        if (!filters) return null;

        for (let key of Object.keys(savedFilters)) {
            filters[key] = savedFilters[key]
        }
    } catch(e) {
        console.warn('TablePagesMixins.loadFilters', e)
    }
}