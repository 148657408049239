export function toFixed(num, precision) {
    const base = 10 ** precision;
    return (Math.round(num * base) / base).toFixed(precision);
}

// default on finnish
let thousandSeparator = ' '
let decimalMark = ','

export function setCurrencySeparators(thousands, decimals) {
    thousandSeparator = thousands
    decimalMark = decimals
}

export function formatCurrency(price, toString = true, decimals = 2) {
    if (toString) {
        if (!price) price = 0;

        const frontMark = price < 0 ? "-" : "";
        
        let numberString = toFixed(price, decimals).replace('.', decimalMark);
        if (frontMark == "-") {
            numberString = numberString.replace('-', "");
        }
        let [ integerPart, decimalPart] = numberString.split(decimalMark)
        
        let withSeparators = '';
        if (thousandSeparator !== "") {
            for (let i = integerPart.length - 1, j = 1; i >= 0; i--, j++) {
                withSeparators = integerPart.charAt(i) + withSeparators;
                if (j % 3 === 0 && i !== 0) {
                    withSeparators = thousandSeparator + withSeparators;
                }
            }
        }

        return`${ frontMark }${ withSeparators }${ decimalMark }${ decimalPart }`;
    } else {
        if (typeof price === 'string') {
            return parseFloat(price.replace(new RegExp('\\' + thousandSeparator, 'g'), "").replace(new RegExp('\\' + decimalMark), '.'));
        } else if (typeof price === 'number') {
            return price; // do nothing, just a failsafe
        } else {
            return 0;
        }
    }
}